<script setup lang="ts">
import DeviceTimerEvent from '@/components/DeviceComponents/DeviceTimerEvent.vue'
import i18n from '@/locales/i18n'
import type { DetectionModel } from '@rfc/rfc-components-library/types'
import { formatTimestamp } from '@rfc/rfc-components-library/utils'
import { provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const route = useRoute()
const store = useStore()
const { t } = useI18n()

provide('router', router)
provide('route', route)
provide('store', store)
provide('t', t)
provide('i18n', i18n)

provide(
    'generateTitle',
    (detection: DetectionModel) =>
        `Bib ${detection?.bib}${detection?.event_filter ? ` - E${detection?.event_filter}` : ''} ${
            detection?.event_filter ? ` - ${detection?.event_filter}` : ''
        }`
)
provide('generateSubtitle', (detection: DetectionModel) =>
    formatTimestamp(detection?.timestamp as Date | string)
)

provide('detectionsSocketPath', 'urtime-detections')
provide('alertsSocketPath', 'device')

provide('DeviceCardHeader', DeviceTimerEvent)
</script>

<template>
    <slot />
</template>
