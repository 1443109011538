import { detectionsFr as libDetectionFr } from '@rfc/rfc-components-library/locales'

export const detectionsFr = {
    ...libDetectionFr,
    filters: {
        ...libDetectionFr.filters,
        bib: 'Bib',
        checkpoint: 'Checkpoint',
        event: 'Évènement',
        eventId: 'Event ID',
        timerId: 'Timer ID'
    },
    model: {
        ...libDetectionFr.model,
        bib: 'Bib',
        checkpointId: 'Checkpoint',
        eventFilter: "ID de l'évènement",
        timerFilter: 'ID du chronométreur'
    },
    table: {
        ...libDetectionFr.table,
        bib: 'Bib',
        checkpointName: 'Checkpoint',
        eventName: 'Évènement',
        eventFilter: "ID de l'évènement",
        timerFilter: 'ID du chronométreur'
    }
}
