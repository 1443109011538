<script setup lang="ts">
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import type { TabHref } from '@/types/components/TabHref'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import { ETableMode, ETableModeIcons, ETableModeValues } from '@/types/enum/TableModeEnum'
import type { AdvancedUsersFilters, UsersFilters } from '@/types/filters/UsersFilters'
import { EOrganizationsUsersPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import {
    ButtonsGroupFilters,
    CheckboxField,
    FullTableLayout,
    HrefCreateButton,
    PermissionWrapper,
    StackLayout
} from '@rfc/rfc-components-library'
import { EOrganizationActions, EUserActions } from '@rfc/rfc-components-library/store'
import { type SelectorObject, type UserModel } from '@rfc/rfc-components-library/types'
import { computed, ref } from 'vue'
import UserFilters from '../../User/components/UserFilters.vue'
import OrganizationUsersTableCardMode from './Table/OrganizationUsersTableCardMode.vue'
import OrganizationUsersTableListMode from './Table/OrganizationUsersTableListMode.vue'

type Props = {
    organizationId: number
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums

    buttonsHref: TabHref
}
const props = defineProps<Props>()
type Emits = {
    (e: 'goToDetail', organizationid: number): void
    (e: 'goToCreate'): void
}
const emit = defineEmits<Emits>()

const loading = ref(false)
const data = computed(() => store.value?.state.organization.users)
const filters = ref<Partial<UsersFilters & AdvancedUsersFilters>>(getQueryFilters())
const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const getUsers = () =>
    store.value?.dispatch(
        `${EStoreModules.ORGANIZATION}/${EOrganizationActions.FETCH_ORGANIZATION_USERS}`,
        {
            params: {
                filters: filters.value,
                sort: getSortByKey(sort.value),
                per_page: data.value?.per_page,
                page: data.value?.current_page
            },
            organizationId: props.organizationId
        }
    )

const goToCreateUser = () => emit('goToCreate')
const goToDetail = (user: UserModel) => {
    if (!user.user_id) return //TODO ALERT
    emit('goToDetail', user.user_id)
}
const handleDeactivate = (value: UserModel) =>
    store.value
        ?.dispatch(`${EStoreModules.USER}/${EUserActions.DELETE_USER}`, value)
        .then(res => (res.status === 204 ? getUsers() : undefined))

const handleFiltersChange = (value: Partial<UserModel>) => {
    filters.value = { ...filters.value, ...value }
    if (data.value) data.value.current_page = 1
    getUsers()
}

const handleAdvancedFilters = (value: Partial<AdvancedUsersFilters>) =>
    (advancedFilters.value = { ...advancedFilters.value, ...value })

const handleSearch = () => {
    filters.value = { ...filters.value, ...advancedFilters.value }
    if (data.value) data.value.current_page = 1
    getUsers()
}

const handleChangePageSize = (value: number) => {
    if (data.value) data.value.per_page = value
    if (data.value) data.value.current_page = 1
    getUsers()
}
const handleChangePageNumber = (value: number) => {
    if (data.value) data.value.current_page = value
    getUsers()
}

const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getUsers()
}

const tableMode = ref<number>(ETableMode.CARD)
const tableModes = ETableModeValues.map(item => ({ value: item, icon: ETableModeIcons[item] }))

const advancedFilters = ref<AdvancedUsersFilters>({})
const filtersTags = computed<SelectorObject[]>(() =>
    Object.entries({
        deactivated: !!filters.value.deactivated
    })
        .map(([name, value]) => ({
            name,
            value
        }))
        .filter(item => !!item.value)
)

const handleRemoveTag = (value: SelectorObject) => {
    filters.value = { ...filters.value, [value.name ?? 'name']: undefined }
    advancedFilters.value = { ...advancedFilters.value, [value.name ?? 'name']: undefined }
    if (data.value) data.value.current_page = 1
}
</script>

<template>
    <StackLayout direction="column" :gap="16">
        <StackLayout direction="row" :gap="8" class="align-self-end">
            <PermissionWrapper :permission="EOrganizationsUsersPerm.ORGANIZATIONS_USERS_EDIT">
                <HrefCreateButton
                    :href="buttonsHref.createButtonHref"
                    :label="$t('organization.users.buttons.createUser')"
                    @click="goToCreateUser" />
            </PermissionWrapper>
        </StackLayout>
        <FullTableLayout
            :sortBy="sort"
            keepInRow
            @changeSortBy="handleChangeSortBy"
            :advancedFilters="filtersTags"
            translationPath="organization.filters"
            @removeTag="handleRemoveTag"
            @search="handleSearch"
            :perPage="data?.per_page"
            :currentPage="data?.current_page"
            :lastPage="data?.last_page"
            :total="data?.total"
            forcePropsCurrentPage
            @change:pageSize="handleChangePageSize"
            @change:pageNumber="handleChangePageNumber">
            <template #filters>
                <UserFilters
                    :name="filters.name"
                    :email="filters.email"
                    @change="handleFiltersChange" />
            </template>
            <template #moreFilters>
                <ButtonsGroupFilters
                    :items="tableModes"
                    :value="tableMode"
                    @click="tableMode = $event as number" />
            </template>

            <template #table>
                <OrganizationUsersTableListMode
                    v-if="tableMode === ETableMode.LIST"
                    :data="data?.data"
                    @click="goToDetail"
                    @delete="handleDeactivate"
                    :editPermission="EOrganizationsUsersPerm.ORGANIZATIONS_USERS_EDIT"
                    :deletePermission="EOrganizationsUsersPerm.ORGANIZATIONS_USERS_DELETE"
                    :editButtonHref="buttonsHref?.editButtonHref"
                    :loading="loading" />
                <OrganizationUsersTableCardMode
                    v-else
                    :data="data?.data"
                    @click="goToDetail"
                    @delete="handleDeactivate"
                    :editPermission="EOrganizationsUsersPerm.ORGANIZATIONS_USERS_EDIT"
                    :deletePermission="EOrganizationsUsersPerm.ORGANIZATIONS_USERS_DELETE"
                    :editButtonHref="buttonsHref?.editButtonHref"
                    :loading="loading" />
            </template>
            <template #dialogContent>
                <CheckboxField
                    :label="$t('organization.filters.deactivated')"
                    @click="handleAdvancedFilters({ deactivated: $event })" />
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
