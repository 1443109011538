<script setup lang="ts">
import { EColors } from '@/types/constants/ColorValues'
import { StackLayout, TypographyItem } from '@rfc/rfc-components-library'
import type { DeviceModel } from '../../types/models/DeviceModel'

type Props = {
    data?: DeviceModel
}
const props = defineProps<Props>()

const keys = ['f1', 'f2']

const formatEIDString = (key: string) =>
    props.data?.config?.[`${key}_evt_val`] ? `EID : ${props.data?.config?.[`${key}_evt_val`]}` : ''
const formatTIDString = (key: string) =>
    props.data?.config?.[`${key}_tmr_val`] ? `TID : ${props.data?.config?.[`${key}_tmr_val`]}` : ''
</script>

<template>
    <StackLayout direction="column">
        <StackLayout v-for="(filter, key) in keys" :key="key">
            <StackLayout
                v-if="data?.config?.[`${filter}_tmr_val`] || data?.config?.[`${filter}_evt_val`]"
                direction="row"
                :gap="8">
                <TypographyItem :label="`${filter.toUpperCase()} :`" />
                <TypographyItem
                    :label="formatEIDString(filter)"
                    :color="
                        data?.config?.[`${filter}_evt_en`] ? EColors.SUCCESS : EColors.DANGER
                    " />
                <TypographyItem
                    :label="formatTIDString(filter)"
                    :color="
                        data?.config?.[`${filter}_tmr_en`] ? EColors.SUCCESS : EColors.DANGER
                    " />
            </StackLayout>
        </StackLayout>
    </StackLayout>
</template>
