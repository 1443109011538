<script setup lang="ts">
import EventCheckpointAutocomplete from '@/components/Filters/EventCheckpointAutocomplete.vue'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import type { DeviceModel } from '@/types/models/DeviceModel'
import { BaseDialog, PrimaryButton, StackLayout } from '@rfc/rfc-components-library'
import { EDeviceActions } from '@rfc/rfc-components-library/store'
import { ref, watch } from 'vue'
import type { EventCheckpointType } from '../../../types/filters/EventCheckpointType'

type Props = {
    device?: Partial<DeviceModel>
}
const props = defineProps<Props>()
type Emits = {
    (e: 'refresh'): Promise<boolean>
}
const emit = defineEmits<Emits>()

const localData = ref<EventCheckpointType>({
    ...props.device
})
watch(
    () => props.device?.event,
    newEvent => (localData.value.event = newEvent)
)

const handleConfirm = () =>
    store.value
        ?.dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.UPDATE_API_DEVICE}`, {
            deviceId: props.device?.device_id ?? props.device?.id,
            deviceData: localData.value
        })
        .then(() => {
            emit('refresh')
            open.value = false
            localData.value.checkpoint = undefined
        })

const handleChange = (value: EventCheckpointType) =>
    (localData.value = { ...localData.value, ...value })

const open = ref(false)
</script>

<template>
    <PrimaryButton
        @click="open = true"
        :label="$t('device.actions.reassignCheckpoint')"
        v-bind="$attrs" />
    <BaseDialog
        :title="$t('device.actions.reassignCheckpoint')"
        :open="open"
        @confirm="handleConfirm"
        @close="open = false"
        class="event-checkpoint-component modal-selector-half-width">
        <template #content>
            <StackLayout :gap="8">
                <EventCheckpointAutocomplete
                    :event="localData?.event?.id === device?.event?.id ? device?.event : undefined"
                    :event_id="localData?.event?.id"
                    :checkpoint_id="localData?.checkpoint?.id"
                    @change="handleChange" />
            </StackLayout>
        </template>
    </BaseDialog>
</template>

<style scoped lang="scss">
.event-checkpoint-component {
    & :deep(.dropdown-content) {
        position: fixed;
        display: flex;
        flex-flow: wrap;
    }
}
</style>
