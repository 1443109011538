import { enumFr as libEnumFr } from '@rfc/rfc-components-library/locales'

export const enumFr = {
    ...libEnumFr,
    keyCategory: {
        authentication: 'Authentification',
        configuration: 'Configuration',
        connectivity: 'Connectivité',
        informations: 'Informations',
        misc: 'Autres',
        rfid: 'RFID',
        specific: 'Spécifique',
        filter1: 'Filtre 1',
        filter2: 'Filtre 2'
    }
}
