<script setup lang="ts">
import { FirefoxDragAndDrop } from '@rfc/rfc-components-library'
import { RouterView, useRoute } from 'vue-router'
import ContextProvider from './components/ContextProvider/ContextProvider.vue'
import { getCurrentThemeMode } from './utils/viewsUtils'

const route = useRoute()
</script>

<template>
    <FirefoxDragAndDrop />
    <div class="app-content">
        <v-responsive>
            <v-app :theme="getCurrentThemeMode">
                <ContextProvider>
                    <RouterView :route="route" />
                </ContextProvider>
            </v-app>
        </v-responsive>
    </div>
</template>
