<script setup lang="ts">
import type { TabHref } from '@/types/components/TabHref'
import type { TabWithTablePermissions } from '@/types/components/TabPermissions'
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import { showTab } from '@/utils/permissionsUtils'
import { StackLayout, TabsComponent } from '@rfc/rfc-components-library'
import type { TabType } from '@rfc/rfc-components-library/types'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import type { PermissionsEnums } from '../../types/permissions/PermissionsEnum'
import OrganizationApiTable from './Apis/Table/OrganizationApiTable.vue'
import OrganizationCustomers from './Customers/OrganizationCustomers.vue'
import OrganizationDevicesTable from './Devices/OrganizationDevicesTable.vue'
import OrganizationRolesTableView from './Roles/OrganizationRolesTableView.vue'
import OrganizationUsers from './Users/OrganizationUsers.vue'

type Props = {
    data: OrganizationModel
    loading?: boolean
    showDevices?: boolean
    showRoles?: boolean

    informationsPermission?: PermissionsEnums

    usersViewPermission?: TabWithTablePermissions
    usersViewButtonsHref: TabHref

    devicesViewPermission?: TabWithTablePermissions
    devicesViewButtonsHref?: TabHref

    customersViewPermission?: TabWithTablePermissions
    customersViewButtonsHref: TabHref

    rolesViewPermission?: TabWithTablePermissions
    rolesViewButtonsHref?: TabHref

    apisViewPermission?: TabWithTablePermissions
    apisViewButtonsHref?: TabHref
}
const props = defineProps<Props>()
type Emits = {
    (e: 'goToDetail', organizationid: number): void
    (e: 'goToCreate'): void
}
defineEmits<Emits>()

const { t } = useI18n()
const route = useRoute()

const hasInformationsViewPermission = computed(() =>
    showTab(
        {
            id: 'informations',
            label: t('organization.tabs.informationsTitle')
        },
        props.informationsPermission
    )
)
const hasUsersViewPermission = computed(() =>
    showTab(
        {
            id: 'users',
            label: t('organization.tabs.usersTitle')
        },
        props.usersViewPermission?.viewPermission
    )
)
const hasDevicesViewPermission = computed(() =>
    props.showDevices
        ? showTab(
              {
                  id: 'devices',
                  label: t('organization.tabs.devicesTitle')
              },
              props.devicesViewPermission?.viewPermission
          )
        : []
)
const hasRolesViewPermission = computed(() =>
    showTab(
        {
            id: 'roles',
            label: t('organization.tabs.rolesTitle')
        },
        props.rolesViewPermission?.viewPermission
    )
)
const hasApisViewPermission = computed(() =>
    showTab(
        {
            id: 'apis',
            label: t('organization.tabs.apisTitle')
        },
        props.apisViewPermission?.viewPermission
    )
)
const tabs = computed<TabType[]>(() => [
    ...hasInformationsViewPermission.value,
    ...hasUsersViewPermission.value,
    ...hasDevicesViewPermission.value,
    ...hasRolesViewPermission.value,
    ...hasApisViewPermission.value
])

const currentTab = computed(() => parseInt((route.params.tab ?? 0).toString()) ?? 0)

const deletePermission = computed(() => ({
    permission: props.devicesViewPermission?.deletePermission
}))
</script>

<template>
    <StackLayout direction="column" :gap="16" isResponsiveSmallScreen>
        <TabsComponent :tabs="tabs">
            <template #informations>
                <slot name="informations" />
            </template>
            <template #users>
                <OrganizationUsers
                    v-if="!!data.id && currentTab === tabs.findIndex(item => item.id === 'users')"
                    :organizationId="data.id"
                    :editPermission="usersViewPermission?.editPermission"
                    :deletePermission="usersViewPermission?.deletePermission"
                    :buttonsHref="usersViewButtonsHref" />
            </template>
            <template #devices>
                <OrganizationDevicesTable
                    v-if="!!data.id && currentTab === tabs.findIndex(item => item.id === 'devices')"
                    :organizationId="data.id"
                    :editPermission="devicesViewPermission?.editPermission"
                    :deletePermission="deletePermission"
                    :buttonsHref="devicesViewButtonsHref" />
            </template>
            <template #customers>
                <OrganizationCustomers
                    v-if="currentTab === tabs.findIndex(item => item.id === 'customers')"
                    :editPermission="customersViewPermission?.editPermission"
                    :deletePermission="customersViewPermission?.deletePermission"
                    :editButtonHref="customersViewButtonsHref?.editButtonHref" />
            </template>
            <template #roles>
                <OrganizationRolesTableView
                    v-if="currentTab === tabs.findIndex(item => item.id === 'roles')"
                    :editPermission="rolesViewPermission?.editPermission"
                    :deletePermission="rolesViewPermission?.deletePermission"
                    :buttonsHref="rolesViewButtonsHref" />
            </template>
            <template #apis>
                <OrganizationApiTable
                    v-if="currentTab === tabs.findIndex(item => item.id === 'apis')"
                    :organizationId="data.id"
                    :editPermission="apisViewPermission?.editPermission"
                    :deletePermission="apisViewPermission?.deletePermission"
                    :buttonsHref="apisViewButtonsHref" />
            </template>
        </TabsComponent>
    </StackLayout>
</template>
