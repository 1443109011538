import type { DetectionsLocales } from '@/types/lang/locales'
import { detectionsEn as libDetectionEn } from '@rfc/rfc-components-library/locales'

export const detectionsEn: DetectionsLocales = {
    ...libDetectionEn,
    filters: {
        ...libDetectionEn.filters,
        bib: 'Bib',
        checkpoint: 'Checkpoint',
        event: 'Event',
        eventId: 'Event ID',
        timerId: 'Timer ID'
    },
    model: {
        ...libDetectionEn.model,
        bib: 'Bib',
        checkpointId: 'Checkpoint',
        eventFilter: 'Event ID',
        timerFilter: 'Timer ID'
    },
    table: {
        ...libDetectionEn.table,
        bib: 'Bib',
        checkpointName: 'Checkpoint',
        eventName: 'Event',
        deviceId: 'Device ID',
        eventFilter: 'Event ID',
        timerFilter: 'Timer ID'
    }
}
