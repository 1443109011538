import moment from 'moment'
import type { PartnerModel } from '../partners/PartnerModel'
import type { AssociatedDeviceModel } from './DeviceModel'
import type { PartnerConfigModel } from './PartnerConfigModel'

export type EventModel = {
    client_id: number
    comments?: string
    end_at?: Date | null
    id: number
    latestDetections?: string[]
    detection_devices?: AssociatedDeviceModel[]
    name: string
    start_at?: Date | null

    organization_id?: number

    partner?: PartnerModel
    partner_config?: Partial<PartnerConfigModel> | null
    partner_id?: number
    tz?: string
}

export const defaultEventModel: EventModel = {
    client_id: 0,
    end_at: moment().endOf('d').toDate(),
    id: 0,
    name: '',
    start_at: moment().startOf('d').toDate()
}
