import router from '@/router'
import type { ESortBy } from '@/types/enum/SortByEnum'
import { formatDate } from '@/utils/dateUtils'
import { isDateValid, isNullOrUndefined } from '@/utils/validateUtils'
import { flatten, unflatten } from 'flat'
import { isEmpty } from 'lodash'
import moment from 'moment'

const valueToString = (key: string, value: string | number): string =>
    value ? `${key}=${value}` : ''

const stringToString = (value: string): string => encodeURIComponent(value)
const numberToString = (value: number): string => value.toString()
const dateToString = (value: Date): string => moment(value).utc().format()
const booleanToString = (value: boolean): string => (value ? 'true' : 'false')
const arrayToString = (key: string, value: any[]) => value.map(item => `${key}[]=${item}`).join('&')

export const buildQueryUrl = (object?: Record<string, unknown>, noQueryParam?: boolean): string => {
    if (!object) return ''
    const entries = Object.entries(object).filter(([key, value]) => key && value)

    const toQueryUrl: Record<string, unknown> = {}

    const queryParamArray = entries.map(([key, value]) => {
        switch (typeof value) {
            case 'string':
                return valueToString(key, stringToString(value))
            case 'number':
                return valueToString(key, numberToString(value))
            case 'boolean':
                return valueToString(key, booleanToString(value))
            case 'object':
                if (isDateValid(value as object)) return dateToString(value as Date)
                if (Array.isArray(value)) return arrayToString(key, value)
                return value
                    ? Object.entries(flatten(value) as object)
                          .reduce((prev: string[], [keyObject, valueObject]) => {
                              if (key === 'filters') toQueryUrl[keyObject] = valueObject
                              return typeof valueObject !== 'boolean' && !!valueObject
                                  ? [
                                        ...prev,
                                        `${key}${keyObject
                                            .split('.')
                                            .map(item => `[${item}]`)
                                            .join('')}=${switchValueToString(valueObject)}`
                                    ]
                                  : prev
                          }, [])
                          .join('&')
                    : ''
            default:
                return undefined
        }
    })

    if (!noQueryParam)
        addToQuery(
            { ...router.currentRoute.value.query, page: object.page, ...toQueryUrl },
            !router.currentRoute.value.query.page ||
                router.currentRoute.value.query.tab !== object.tab
        )

    return `?${queryParamArray.reduce(
        (prev, currentItem) => (currentItem ? `${prev ? `${prev}&` : prev}${currentItem}` : prev),
        ''
    )}`
}

const switchValueToString = (value: any): string => {
    switch (typeof value) {
        case 'string':
            if (isDateValid(formatDate(moment(new Date(value)).utc().toDate()))) return value
            return stringToString(value)
        case 'number':
            return numberToString(value)
        case 'boolean':
            return booleanToString(value)
        case 'object':
            if (isDateValid(value)) return dateToString(value as Date)
            return Object.entries(value)
                .map(([key, objectValue]) => `[${key}]${switchValueToString(objectValue)}`)
                .join()
        default:
            return ''
    }
}

export const decodeQueryParam = (value: string) => decodeURIComponent(value.replace(/\+/g, ' '))

export const getQueryFilters = (): Record<string, string> => {
    const params: Record<string, string> = {}
    new URLSearchParams(window.location.search).forEach((value, key) => (params[key] = value))
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tab, page, per_page, ...otherParams } = params
    return isEmpty(otherParams)
        ? {}
        : unflatten(
              Object.entries(otherParams).reduce(
                  (prev, [key, value]) => ({
                      ...prev,
                      [key]: !isNaN(Number(value)) ? parseInt(value) : value
                  }),
                  {}
              )
          )
}

export const getQuerySort = (): ESortBy | undefined => {
    const params = new URLSearchParams(window.location.search)
    const found = Array.from(params.entries()).find(([key, value]) => key.includes('sort['))
    return found ? (found[1] as ESortBy) : undefined
}

export const addToQuery = (value: object, replace?: boolean) => {
    const flattened = Object.entries(flatten(value) as object).reduce(
        (prev, [key, keyValue]) => ({
            ...prev,
            [key]: !isNullOrUndefined(keyValue) ? switchValueToString(keyValue) : undefined
        }),
        {}
    )
    if (replace)
        return router.replace({ query: { ...router.currentRoute.value.query, ...flattened } })
    router.push({ query: { ...router.currentRoute.value.query, ...flattened } })
}
