<script setup lang="ts">
import router from '@/router'
import { ORGANIZATIONS_CUSTOMERS_ROUTES } from '@/router/routing/organizationRouting/organizationRoutes'
import { ESortBy } from '@/types/enum/SortByEnum'
import { ETableMode, ETableModeIcons, ETableModeValues } from '@/types/enum/TableModeEnum'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { getQuerySort } from '@/utils/queryUtils'
import {
    ButtonsGroupFilters,
    FullTableLayout,
    HrefCreateButton,
    PermissionWrapper,
    StackLayout
} from '@rfc/rfc-components-library'
import { type UserModel } from '@rfc/rfc-components-library/types'
import { ref } from 'vue'
import OrganizationCustomersTableCardMode from './Table/OrganizationCustomersTableCardMode.vue'
import OrganizationCustomersTableListMode from './Table/OrganizationCustomersTableListMode.vue'

type Props = {
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums

    editButtonHref?: string
}
defineProps<Props>()

const loading = ref(false)
const data = ref({
    from: 0,
    per_page: 0,
    to: 0,

    current_page: 1,
    last_page: 0,
    total: 0,
    data: {
        users: []
    }
})
const sort = ref(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const goToDetail = (user: UserModel) =>
    router.push(ORGANIZATIONS_CUSTOMERS_ROUTES.buildUrl(user.id))

/* const handleSearch = () => {
    filters.value = { ...filters.value, ...advancedFilters.value }
    data.value.current_page = 1
    getDetections()
} */

const handleChangePageSize = (value: number) => {
    data.value.per_page = value
    data.value.current_page = 1
    /* getCustomers() */
}
const handleChangePageNumber = (value: number) => {
    data.value.current_page = value
    /* getCustomers() */
}

const tableMode = ref<number>(ETableMode.CARD)
const tableModes = ETableModeValues.map(item => ({ value: item, icon: ETableModeIcons[item] }))
</script>

<template>
    <StackLayout direction="column" :gap="16">
        <PermissionWrapper :permission="editPermission">
            <HrefCreateButton
                :label="$t('organization.organizations.buttons.addOrganization')"
                :href="ORGANIZATIONS_CUSTOMERS_ROUTES.generateUrl" />
        </PermissionWrapper>
        <FullTableLayout
            :sortBy="sort"
            @changeSortBy="$emit('changeSortBy', $event)"
            :perPage="data.per_page"
            :currentPage="data.current_page"
            :lastPage="data.last_page"
            :total="data.total"
            forcePropsCurrentPage
            @change:pageSize="handleChangePageSize"
            @change:pageNumber="handleChangePageNumber">
            <template #filters>
                <slot name="filters" />
            </template>
            <template #moreFilters>
                <ButtonsGroupFilters
                    :items="tableModes"
                    :value="tableMode"
                    @click="tableMode = $event as number" />
            </template>

            <template #table>
                <OrganizationCustomersTableListMode
                    v-if="tableMode === ETableMode.LIST"
                    :data="data"
                    @click="goToDetail"
                    @delete="console.log('delete', $event)"
                    :editPermission="editPermission"
                    :deletePermission="deletePermission"
                    :editButtonHref="editButtonHref"
                    :loading="loading" />
                <OrganizationCustomersTableCardMode
                    v-else
                    :data="data.data.users"
                    @click="goToDetail"
                    @delete="console.log('delete', $event)"
                    :editPermission="editPermission"
                    :deletePermission="deletePermission"
                    :editButtonHref="editButtonHref"
                    :loading="loading" />
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
