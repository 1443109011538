import i18n from '@/locales/i18n'
import { SUPPORTFAQ_ROUTE } from '@/router/routes'
import type { RouteType } from '@/types/Route'
import SupportFAQView from '@/views/SupportFAQView/SupportFAQView.vue'

export enum ESupportFaqRoutes {
    SUPPORT_FAQ_TABLE = 'supportFaqTable',
    SUPPORT_FAQ_DETAIL = 'supportFaqDetail'
}
export const ESupportFaqRoutesValues = [
    ESupportFaqRoutes.SUPPORT_FAQ_TABLE,
    ESupportFaqRoutes.SUPPORT_FAQ_DETAIL
]

const routes: RouteType[] = [
    {
        path: SUPPORTFAQ_ROUTE,
        name: ESupportFaqRoutes.SUPPORT_FAQ_TABLE,
        meta: {
            title: i18n.global.t('menu.supportFaq')
        },
        component: SupportFAQView
    },
    {
        path: `${SUPPORTFAQ_ROUTE}/:id`,
        name: ESupportFaqRoutes.SUPPORT_FAQ_DETAIL,
        component: SupportFAQView,
        meta: {
            title: i18n.global.t('menu.supportFaq')
        }
    }
]

export default routes
