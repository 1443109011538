import type { LangLocales } from '@/types/lang/locales'
import {
    accountEn,
    accountFr,
    addressEn,
    addressFr,
    administrationEn,
    administrationFr,
    apiEn,
    apiFr,
    authenticationEn,
    authenticationFr,
    dateEn,
    dateFr,
    deviceConfigEn,
    deviceConfigFr,
    en,
    errorEn,
    errorFr,
    fr,
    homeEn,
    homeFr,
    organizationEn,
    organizationFr,
    paginationEn,
    paginationFr,
    roleEn,
    roleFr,
    supportEn,
    supportFr,
    unitsEn,
    userEn,
    userFr
} from '@rfc/rfc-components-library/locales'
import { createI18n } from 'vue-i18n'
import { checkpointsEn } from './en/checkpointsEn'
import { detectionsEn } from './en/detectionsEn'
import { devicesEn } from './en/devicesEn'
import { enumEn } from './en/enumEn'
import { eventsEn } from './en/eventsEn'
import { menuEn } from './en/menuEn'
import { partnerEn } from './en/partnerEn'
import { checkpointsFr } from './fr/checkpointsFr'
import { detectionsFr } from './fr/detectionsFr'
import { devicesFr } from './fr/devicesFr'
import { enumFr } from './fr/enumFr'
import { eventsFr } from './fr/eventsFr'
import { menuFr } from './fr/menuFr'
import { partnerFr } from './fr/partnerFr'
import { getLang } from './langUtils'

export type AvailableLangs<T = string> = Record<string, T>
export const defaultAvailableLangs: AvailableLangs<undefined> = {
    fr: undefined,
    en: undefined
}

export type AvailableLangsType = AvailableLangs<LangLocales>

export const capitalizedAvailableLangs = Object.keys(defaultAvailableLangs).map(
    item => item.charAt(0).toUpperCase() + item.slice(1)
)

const messages: AvailableLangsType = {
    en: {
        account: accountEn,
        administration: administrationEn,
        address: addressEn,
        api: apiEn,
        authentication: authenticationEn,
        checkpoint: checkpointsEn,
        common: en,
        date: dateEn,
        detection: detectionsEn,
        device: devicesEn,
        deviceConfig: deviceConfigEn,
        enum: enumEn,
        error: errorEn,
        event: eventsEn,
        home: homeEn,
        menu: menuEn,
        organization: organizationEn,
        pagination: paginationEn,
        partner: partnerEn,
        role: roleEn,
        support: supportEn,
        unit: unitsEn,
        user: userEn
    },
    fr: {
        account: accountFr,
        administration: administrationFr,
        address: addressFr,
        api: apiFr,
        authentication: authenticationFr,
        checkpoint: checkpointsFr,
        common: fr,
        date: dateFr,
        detection: detectionsFr,
        device: devicesFr,
        deviceConfig: deviceConfigFr,
        enum: enumFr,
        error: errorFr,
        event: eventsFr,
        home: homeFr,
        menu: menuFr,
        organization: organizationFr,
        pagination: paginationFr,
        partner: partnerFr,
        role: roleFr,
        support: supportFr,
        unit: unitsEn,
        user: userFr
    }
}

export const i18n = createI18n({
    allowComposition: true,
    legacy: false,
    locale: getLang() ?? 'en',
    fallbackLocale: 'en',
    globalInjection: true,
    messages
})

export default i18n
