import {
    API_ENV_URL as API_ENV_URL_LIB,
    AUTH_ENV_URL as AUTH_ENV_URL_LIB,
    CRM_ENV_URL as CRM_ENV_URL_LIB,
    DEVICE_ENV_URL as DEVICE_ENV_URL_LIB
} from '@rfc/rfc-components-library/config'
import type { AxiosRequestConfig } from 'axios'

//old version
// export const AUTH_URL = 'http://auth.test:8080'
// export const API_URL = 'http://ticketing.test'

// export const AUTH_PREFIX = `${AUTH_URL}/auth`
// export const API_VERSION = '/api/v1'

// export const API_PREFIX = `${API_URL}${API_VERSION}`
// export const AUTH_API_PREFIX = `${AUTH_PREFIX}${API_VERSION}`
export const AUTH_ENV_URL =
    import.meta?.env?.VITE_RFC_AUTH_URL ?? AUTH_ENV_URL_LIB ?? window.location.origin
export const API_ENV_URL =
    import.meta?.env?.VITE_URTIME_HUB_API_URL ?? API_ENV_URL_LIB ?? window.location.origin
export const CRM_ENV_URL =
    import.meta?.env?.VITE_RFC_CRM_URL ?? CRM_ENV_URL_LIB ?? window.location.origin
export const DEVICE_ENV_URL =
    import.meta?.env?.VITE_RFC_DEVICE_URL ?? DEVICE_ENV_URL_LIB ?? window.location.origin

export const AUTH_PREFIX = `${AUTH_ENV_URL}/auth`
export const API_VERSION = '/api/v1'

export const AUTH_API_PREFIX = `${AUTH_ENV_URL}/auth${API_VERSION}`
export const API_PREFIX = `${API_ENV_URL}${API_VERSION}`
export const CRM_PREFIX = `${CRM_ENV_URL}/crm${API_VERSION}`
export const DEVICE_PREFIX = `${DEVICE_ENV_URL}/device${API_VERSION}`

export const RETRY_DELAY = 30000

export const defaultRequest: AxiosRequestConfig = {
    timeout: RETRY_DELAY, // indicates, 1000ms ie. 1 second
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
}

export const POST_REQUEST: AxiosRequestConfig = {
    ...defaultRequest,
    method: 'POST'
}

export const GET_REQUEST: AxiosRequestConfig = {
    ...defaultRequest,
    method: 'GET'
}

export const PUT_REQUEST: AxiosRequestConfig = {
    ...defaultRequest,
    method: 'PUT'
}

export const PATCH_REQUEST: AxiosRequestConfig = {
    ...defaultRequest,
    method: 'PATCH'
}

export const DELETE_REQUEST: AxiosRequestConfig = {
    ...defaultRequest,
    method: 'DELETE'
}

export const MULTIPART_FORMDATA_REQUEST: AxiosRequestConfig = {
    ...defaultRequest,
    headers: {
        'Content-type': 'multipart/form-data'
    }
}

export const GET_RESPONSE_TYPE_BLOB_REQUEST: AxiosRequestConfig = {
    ...defaultRequest,
    headers: {
        'Content-type': 'application/pdf'
    },
    method: 'GET',
    responseType: 'blob'
}

export const GET_RESPONSE_TYPE_CSV_REQUEST: AxiosRequestConfig = {
    ...defaultRequest,
    headers: {
        'Content-type': 'text/csv'
    },
    method: 'GET'
}
