<script setup lang="ts">
import { EVENTS_ROUTES } from '@/router/routes'
import { EEventActions } from '@/store/eventStore/EventStoreTypes'
import { EStoreModules } from '@/store/storeType'
import {
    defaultAdvancedEventFilters,
    defaultEventFilters,
    type AdvancedEventFilters,
    type EventFilters
} from '@/types/filters/EventFilters'
import type { EventModel } from '@/types/models/EventModel'
import { EEventsPerm } from '@/types/permissions/EventsPermissionsEnum'
import { DATETIME_FORMAT } from '@/utils/dateUtils'
import { getQueryFilters } from '@/utils/queryUtils'
import {
    ClickableTypography,
    FullTableLayout,
    HrefCreateButton,
    PermissionWrapper
} from '@rfc/rfc-components-library'
import { useTableModel } from '@rfc/rfc-components-library/hooks'
import type { StoreState } from '@rfc/rfc-components-library/store'
import {
    ETableMode,
    ETableModeIcons,
    ETableModeValues,
    type SelectorObject
} from '@rfc/rfc-components-library/types'
import moment from 'moment'
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import type { Router } from 'vue-router'
import type { Store } from 'vuex'
import EventAdvancedFilters from './Table/Filters/EventAdvancedFilters.vue'
import EventTableFilters from './Table/Filters/EventTableFilters.vue'

const { t } = useI18n()
const store = inject<Store<StoreState> | undefined>('store', undefined)
const router = inject<Router | undefined>('router', undefined)

const columns = [
    { value: 'name', title: t('event.table.eventName') },
    { value: 'start_at', title: t('event.table.startDate') },
    { value: 'end_at', title: t('event.table.endDate') }
]

const filters = ref<EventFilters & Partial<AdvancedEventFilters>>({
    ...defaultEventFilters,
    ...defaultAdvancedEventFilters,
    ...getQueryFilters()
})

const eventModel = useTableModel<EventModel, Partial<EventModel>, AdvancedEventFilters>(
    store,
    EStoreModules.EVENT,
    EEventActions.FETCH_EVENTS,
    router,
    filters.value
)

const goToEventDetail = (event: EventModel) => router?.push(EVENTS_ROUTES.buildUrl(event.id))
const goToEventCreate = () => router?.push(EVENTS_ROUTES.createUrl)

const advancedFilters = ref<Partial<AdvancedEventFilters>>({ ...defaultAdvancedEventFilters })
const filtersTags = computed(() =>
    Object.entries({
        ongoing: filters.value.ongoing,
        upcoming: filters.value.upcoming,
        finished: filters.value.finished
    }).reduce(
        (prev: SelectorObject[], [key, value]) =>
            value ? [...prev, { name: key, value: !!value }] : prev,
        []
    )
)

const tableMode = ref(ETableMode.CARD)
const tableModes = [...ETableModeValues].map(item => ETableModeIcons[item])

onMounted(() => eventModel.getData())

watch(
    () => eventModel.data.value,
    newData => console.log({ newData })
)
</script>

<template>
    <FullTableLayout
        showAdvancedFilters
        :sortBy="eventModel.sort.value"
        @search="eventModel.handleSearch"
        @changeSortBy="eventModel.handleChangeSortBy"
        translationPath="event.filters"
        @removeTag="eventModel.handleRemoveTag"
        @click:row="console.log({ $event })"
        :data="eventModel.data.value"
        :columns="columns"
        @change:pageSize="eventModel.handleChangePageSize"
        @change:pageNumber="eventModel.handleChangePageNumber">
        <template #filters>
            <EventTableFilters :filters="filters" @change="eventModel.handleChangeFilters" />
        </template>
        <template #button>
            <PermissionWrapper :permission="EEventsPerm.EVENTS_EDIT">
                <HrefCreateButton
                    :href="EVENTS_ROUTES.createUrl"
                    :label="$t('event.createEvent')"
                    @click="goToEventCreate" />
            </PermissionWrapper>
        </template>

        <template #name="item">
            <ClickableTypography :label="item?.name" :href="EVENTS_ROUTES.buildUrl(item?.id)" />
        </template>
        <template #start_at="item">
            {{ moment(item?.start_at).utc().format(DATETIME_FORMAT) }}
        </template>
        <template #end_at="item">
            {{ moment(item?.end_at).utc().format(DATETIME_FORMAT) }}
        </template>

        <template #dialogContent>
            <EventAdvancedFilters
                :filters="advancedFilters"
                @change="eventModel.handleChangeAdvancedFilters" />
        </template>
    </FullTableLayout>
</template>

<style scoped lang="scss">
.centered-pagination {
    padding: 8px;
}
</style>
