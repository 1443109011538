import type { EnumLocales } from '@/types/lang/locales'
import { enumEn as libEnumEn } from '@rfc/rfc-components-library/locales'

export const enumEn: EnumLocales = {
    ...libEnumEn,
    keyCategory: {
        authentication: 'Authentification',
        configuration: 'Configuration',
        connectivity: 'Connectivité',
        informations: 'Informations',
        misc: 'Autres',
        rfid: 'RFID',
        specific: 'Spécifique',
        filter1: 'Filtre 1',
        filter2: 'Filtre 2'
    }
}
