import i18n from '@/locales/i18n'
import { DEVICE_ROUTES } from '@/router/routes'
import type { RouteType } from '@/types/Route'
import { EOrganizationsDevicesPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import DeviceCreateViewVue from '@/views/DevicesView/DeviceCreateView.vue'
import DeviceDetailViewVue from '@/views/DevicesView/DeviceDetailView.vue'
import { DevicesTablePage } from '@rfc/rfc-components-library'
import { EDeviceActions } from '@rfc/rfc-components-library/store'
import { EStoreModules } from '../../store/storeType'

export enum EDeviceRoutesNames {
    DEVICE_TABLE = 'deviceTable',
    DEVICE_DETAIL = 'deviceDetail',
    DEVICE_CREATE = 'deviceCreate'
}
export const EDeviceRoutesNamesValues = [
    EDeviceRoutesNames.DEVICE_TABLE,
    EDeviceRoutesNames.DEVICE_DETAIL,
    EDeviceRoutesNames.DEVICE_CREATE
]

const routesDevices: RouteType[] = [
    {
        path: DEVICE_ROUTES.baseUrl,
        name: EDeviceRoutesNames.DEVICE_TABLE,
        component: DevicesTablePage,
        props: {
            storeKey: EStoreModules.DEVICE,
            fetchKey: EDeviceActions.FETCH_DEVICES,
            socketUrl: import.meta.env.VITE_SOCKET_URL
        },
        meta: {
            title: i18n.global.t('menu.devices'),
            permission: EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_VIEW
        }
    },
    {
        path: DEVICE_ROUTES.detailUrl,
        name: EDeviceRoutesNames.DEVICE_DETAIL,
        component: DeviceDetailViewVue,
        meta: {
            title: i18n.global.t('menu.devices'),
            permission: EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_VIEW
        }
    },
    {
        path: DEVICE_ROUTES.createUrl,
        name: EDeviceRoutesNames.DEVICE_CREATE,
        component: DeviceCreateViewVue,
        meta: {
            title: i18n.global.t('menu.devices'),
            permission: EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_EDIT
        }
    }
]

export default routesDevices
